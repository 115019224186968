
export enum InstrumentTypes {
    NULL = -Infinity,
    SINEWAVE = 0,
    // BASSELECTRIC,
    // BASSOON,
    // CELLO,
    // CLARINET,
    // CONTRABASS,
    // FLUTE,
    // FRENCHHORN,
    // GUITARACOUSTIC,
    // GUITARELECTRIC,
    // GUITARNYLON,
    PIANO//,
    // HARMONIUM,
    // HARP,
    // ORGAN,
    // SAXOPHONE,
    // TROMBONE,
    // TRUMPET,
    // TUBA,
    // VIOLIN,
    // XYLOPHONE,
}

export enum NoteDurations {
    NULL = -Infinity,
    WHOLE = 0,
    HALF = 1,
    QUARTER = 2,
    EIGHTH = 3,
    SIXTEENTH = 4,
}

export enum KeyGroups {
    "Major",
    "Minor",
    "Chromatic",
}

export enum Keys {
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B"
}